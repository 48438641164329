import {
    Modal
} from "bootstrap";

document.addEventListener("DOMContentLoaded", function () {
    if (_THULEDATA.IsEditing) {
        return;
    }

    let wrongMarket = document.getElementById("wrongMarket");
    let wrongMarketModal = new Modal(wrongMarket);
    let isWrongMarket = document.getElementById("isWrongMarket");

    if (isWrongMarket.classList.contains("True") && !checkWrongMarketCookie()) {
        let wrongMarketButton = document.getElementById("wrongMarketButton");
        wrongMarketModal.show();
        wrongMarketButton.onclick = () => {
            wrongMarketModal.hide();

            let targetUrl = wrongMarket.dataset.targeturl;
            window.location.href = targetUrl;
        }

        let wrongMarketSelectButton = document.getElementById("wrongMarketSelectButton");
        wrongMarketSelectButton.onclick = () => {
            wrongMarketModal.hide()
        };

        setWrongMarketCookie();
    }

    function checkWrongMarketCookie() {
        return document.cookie.includes("WrongMarket");
    };

    function setWrongMarketCookie() {
        let date = new Date(Date.now());
        date.setDate(date.getDate() + 30);

        let cookieString = "WrongMarket=WrongMarket; expires=" + date.toString() + "; path=/";
        document.cookie = cookieString;
    }
});